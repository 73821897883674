<template>
    <div class="contaners1">
        <div class="audit">
            <div class="audit_list" v-for="(item,index) in tableData" v-bind:key="index" @click="auditBtn(item.audit_id,item)">
                <div class="audit_msg">
                    <div v-if="item.union_type==1" class="audit_title">申请工会会员</div>
                    <div v-if="item.union_type==2" class="audit_title">股东报名</div>
                    <div v-if="item.union_type==3&&item.audit.audit_type==1" class="audit_title">工会会员激活</div>
                    <div v-if="item.union_type==3&&item.audit.audit_type==2" class="audit_title">股东激活</div>
                    <div>申请日期：{{item.audit.create_time.split(" ")[0]}}</div>
                </div>
                <div class="audit_img">
                    <img v-if="item.audit.audit_state==2" src="../../assets/successIcon.png">
                    <img v-if="item.audit.audit_state==3" src="../../assets/erroricon.png">
                    <img v-if="item.audit.audit_state==1||item.audit.audit_state==0" src="../../assets/auditing.png">
                    <div v-if="item.audit.audit_state==1||item.audit.audit_state==0" style="color:#F46C14;margin-top:5px">审核中</div>
                    <div v-if="item.audit.audit_state==2" style="color:#07C160;margin-top:5px">审核成功</div>
                    <div  v-if="item.audit.audit_state==3" style="color:#FF5E5E;margin-top:5px">审核失败</div>
                </div>
                <img v-if="item.audit.audit_state==1||item.audit.audit_state==0" style="width:30px;height:30px;margin-top:10px" src="../../assets/righticon2.png" />
                <img v-if="item.audit.audit_state==2" style="width:30px;height:30px;margin-top:10px" src="../../assets/righticon3.png" />
                <img v-if="item.audit.audit_state==3" style="width:30px;height:30px;margin-top:10px" src="../../assets/righticon1.png" />
            </div>
        </div>
    </div>
</template>

<script scoped type="">
import * as apiCommon from "@/api/common";
    export default{
        data(){
            return{
                tableData:''
            }
        },
        created(){
            document.title="审核记录";
            this.getList();
        },
        methods:{
            async getList(){
                let res = await apiCommon.auditList();
                // if(res.code=0){
                    console.log(res,"res");
                    this.tableData = res.data
                // }
            },
            auditBtn(id,item){
                console.log(item,item)
                if(item.union_type==1){
                    this.$router.push(`../lador/orderDetail?id=${id}`);
                    return false;
                }
                if(item.union_type==3){
                    // if(item.audit.audit_type==2){
                    //     
                    // }else{
                    //     this.$router.push(`../shareholders/offline/flow?audit_id=${id}`)
                    // }
                    this.$router.push(`../shareholders/activate/details?id=${id}`)
                }else{
                    if(item.audit.audit_type==1){
                        this.$router.push(`../shareholders/online/onlineDetails?audit_id=${id}`)
                    }else{
                        this.$router.push(`../shareholders/offline/flow?audit_id=${id}`)
                    }
                    
                }
                
            }
        }
    }
</script>
<style scoped>
    .contaners1{
        background-color: #fff;
    }
    .audit{
        padding:27px 31px;
    }
    .audit_list{
        width: 689px;
        height: 200px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
        opacity: 1;
        border-radius: 10px;
        padding:35px 40px;
        box-sizing: border-box;
        font-size: 30px;
        display:flex
    }
    .audit_img img{
        width:100px;
        height: 94px
    }
    .audit_msg{
        margin-top: 5px
    }
    .audit_title{
        font-size: 40px;
        margin-bottom: 20px
    }
    .audit_img{
        margin-left: 100px;
        width:125px;
        text-align: center
    }
</style>
